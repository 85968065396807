import React from "react";

import BackgroundCanvas from "../Components/BackgroundCanvas";
import { Link } from "react-router-dom";

const Home = () => {
  const [scroll, setScroll] = React.useState(() => {
    return { top: 0, maxDepth: 1000 };
  });

  const isShopActive = false;

  const handleScroll = () => {
    const scrollTop = document.getElementById("homeWrapper").scrollTop;
    const maxDepth =
      document.getElementById("homeWrapper").scrollHeight -
      document.getElementById("homeWrapper").clientHeight;
    console.log(scrollTop);
    setScroll(() => {
      return { top: scrollTop, maxDepth: maxDepth };
    });
  };

  return (
    <div
      id="homeWrapper"
      className="relative overflow-auto"
      onScroll={handleScroll}
    >
      <BackgroundCanvas scroll={scroll} />

      {/* Hero Section */}
      <section className="text-sky-50 py-20 relative z-10 h-screen">
        <div className="mx-auto px-6 text-center items-center flex flex-column justify-center h-full">
          <h1 className="text-9xl font-bold mb-2">You Dream, We Deliver</h1>
          <p className="text-6xl mb-7 mt-7">Innovate | Create | Inspire</p>
        </div>
      </section>

      {/* Services Section */}
      <section id="features" className="py-40 relative z-10 min-h-screen">
        <div className="text-sky-50  mx-auto px-6 w-4/5 h-full">
          <h2 className="text-5xl font-bold text-center mb-12">Services</h2>
          <div className="w-full flex flex-wrap h-full">
            <div className="w-full md:w-1/4 px-4 mb-8 h-130">
              <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <h3 className="text-3xl text-center font-bold mb-4 ">
                  FDM Printing
                </h3>
                <p className="text-sky-100 flex-grow-0 h-1/3">
                  Transform your ideas into reality with our high-end industrial
                  printers, offering affordable prototyping and instant quotes
                </p>
                <div className="mt-4 flex-grow h-2/3">
                  <img
                    src="https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424629/printit_masqjd.jpg"
                    alt="3D Printing"
                    className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-8 h-130">
              <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <h3 className="text-3xl text-center font-bold mb-4">
                  Resin Printing
                </h3>
                <p className="text-sky-100 flex-grow-0 h-1/3">
                  Experience the finest high-resolution miniature prints with
                  our SLA 3D printed parts
                </p>
                <div className="mt-4 flex-grow h-2/3">
                  <img
                    src="https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424622/resin_avzto7.jpg"
                    alt="3D Printing"
                    className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-8 h-130">
              <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <h3 className="text-3xl text-center font-bold mb-4">Shop</h3>
                <p className="text-sky-100 flex-grow-0 h-1/3">
                  Explore our collection of pre-designed models, printed and
                  shipped directly to you
                </p>
                <div className="mt-4 flex-grow h-2/3">
                  <img
                    src="https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424619/shop_qiszv5.jpg"
                    alt="3D Printing"
                    className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-8 h-130">
              <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <h3 className="text-3xl text-center font-bold mb-4">
                  Website Design
                </h3>
                <p className="text-sky-100 flex-grow-0 h-1/3">
                  We offer comprehensive design services to help you create
                  modern, highly customizable web applications and websites.
                  Connect with us to bring your ideas to life
                </p>
                <div className="mt-4 flex-grow h-2/3">
                  <img
                    src="https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424611/webDesign_cyighi.jpg"
                    alt="3D Printing"
                    className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Design section*/}
      <section className="py-40 relative z-10 min-h-screen">
        <div className="text-sky-950 bg-opacity-60 bg-sky-50 text-center mx-auto w-5/5 h-full">
          <div className="bg-none flex items-center">
            <img
              src="https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424633/designyourown_yiarr9.jpg"
              alt="Design"
              className="w-1/2 h-full object-fill transform transition-transform duration-500 hover:scale-103"
            />
            <div className="ml-6 h-full p-10">
              <h3 className="text-4xl font-bold mb-4">Design Your Ideas</h3>
              <p className="text-sky-900 text-2xl">
                Bring Your Vision to Life with Our Design Expertise. Our
                professional in-house designers are here to help you transform
                your imagination into reality. Whether you have a rough concept
                or a detailed plan, we offer comprehensive design services to
                meet your needs. Connect with us today to start your design
                journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*print it now*/}
      <section className="py-40 relative z-10 mt-40 mb-60">
        <div className="text-sky-50 text-center mx-auto w-5/5 ">
          <div className="bg-sky-950 bg-opacity-30 flex items-center">
            <div className="shadow-lg w-full md:w-1/2">
              <div className="p-10">
                <h3 className="text-6xl font-bold mb-4">Print It Now</h3>
                <p className="text-3xl text-sky-100">
                  Get your designs printed with our state-of-the-art 3D printing
                  services. Upload your design, choose your material, and get an
                  instant quote. Our professional team ensures high-quality
                  prints delivered to your doorstep.
                </p>
                <Link to="/3dpservice">
                  <button className="mt-6 bg-sky-50 text-sky-900 fs-4 font-semibold py-2 px-4 hover:skew-y-3 transition duration-150 ease-in-out">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
            <div className="w-full md:w-1/2 ">
              <div className="relative z-10">
                <img
                  src="/images/printit.jpg"
                  alt="Print Now"
                  className="w-full h-svh object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*shop products*/}
      {isShopActive && (
        <section className="py-40 relative z-10 min-h-screen">
          <div className="text-sky-50 text-center mx-auto px-6 w-5/5">
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-2">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product1.jpg"
                      alt="Product 1"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 1 Description</p>
                  </div>
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product2.jpg"
                      alt="Product 2"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 2 Description</p>
                  </div>
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product3.jpg"
                      alt="Product 3"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 3 Description</p>
                  </div>
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product4.jpg"
                      alt="Product 4"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 4 Description</p>
                  </div>
                </div>
              </div>
              <div className="col-span-1"></div>
              <div className="col-span-2">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product5.jpg"
                      alt="Product 5"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 5 Description</p>
                  </div>
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product6.jpg"
                      alt="Product 6"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 6 Description</p>
                  </div>
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product7.jpg"
                      alt="Product 7"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 7 Description</p>
                  </div>
                  <div className="bg-sky-950 bg-opacity-75 rounded-lg shadow-lg p-6 h-full flex flex-col">
                    <img
                      src="/images/product8.jpg"
                      alt="Product 8"
                      className="w-full h-48 object-cover rounded-lg transform transition-transform duration-500 hover:scale-105"
                    />
                    <p className="text-sky-100 mt-4">Product 8 Description</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/*Dummy*/}
      {!isShopActive && (
        <section className="py-40 relative z-10 min-h-screen">
          <div className="text-sky-50 text-center mx-auto w-5/5 "></div>
        </section>
      )}
    </div>
  );
};

export default Home;
