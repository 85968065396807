import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const shopEnabled = false;

  return (
    <nav className="bg-light py-4 header h-20">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424612/logo_ic03mc.png"
              alt="Company Logo"
              className="h-12 mr-4"
            />
          </Link>
        </div>
        <ul className="flex justify-center space-x-8">
          <li className="nav-item hover:border-b-2 hover:border-sky-950 transition duration-300 ease-in-out ">
            <Link className="nav-link fs-5 font-semibold " to="/">
              Home
            </Link>
          </li>
          {shopEnabled && (
            <li className="nav-item hover:border-b-2 hover:border-sky-950 transition duration-300 ease-in-out">
              <Link className="nav-link fs-5 font-semibold " to="/shop">
                Shop
              </Link>
            </li>
          )}
          <li className="nav-item hover:border-b-2 hover:border-sky-950 transition duration-300 ease-in-out">
            <Link className="nav-link fs-5 font-semibold" to="/3dpservice">
              3D Printing Service
            </Link>
          </li>
          <li className="nav-item hover:border-b-2 hover:border-sky-950 transition duration-300 ease-in-out">
            <Link className="nav-link fs-5 font-semibold" to="/webDesign">
              Website Design
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
